var  base_url = "https://docendorse.com",
attachment = [],
fileStore = [],
refreshOnDialogClose = false,
dialogBox = null,
isRunning = false,
uploadRedact = null,
uploadSign = null,
main_container = grab('.main_container'),
secondary_container = grab('.profile-card'),
loading_cover = grab('.loading_cover'),
spinner = grab('.spinner'),
acceptableFiles = [
  'image/jpeg',
  'image/png',
  'image/bmp',
  'application/vnd.ms-powerpoint',
  'application/pdf',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/msword',
  'application/vnd.oasis.opendocument.presentation',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
],

acceptTableExtensions = [
  'pdf','jpeg','jpg','docx','doc','ppt','pptx','png','bmp'
],

file_template = '<div class="signature_list_item">'+
                    '<span class="d-flex">'+
                        '<img class="regular_background image-icon" src="{2}/sitepkg/img/file_template.png" width="20">'+
                        '<p class="title">{0}</p>'+
                        '<input name="document_id" type="hidden" value="{1}">'+
                        '<input name="document_type" type="hidden" value="{3}">'+
                        '<span class="mdi mdi-close-circle-outline align-self-center ml-auto"></span>'+
                    '</span>'+
                '</div>',
recipient_template = '<span class="d-block msd-menu-option">'+
                        '<label class="checkbox style-c">'+
                            '<input data-no-serialize="" name="{0}" type="checkbox">'+
                            '<div class="checkbox__checkmark"></div>'+
                            '<div class="checkbox__body">{1}</div>'+
                        '</label>'+
                      '</span>';


function installAddIn(info){
  if (info.host === Office.HostType.Outlook) {
    secondary_container.hideItem();
    main_container.showItem();
   
    var mailbox = Office.context.mailbox,
    attachments = mailbox.item.attachments,
    nav_links = Array.from(grabAll('.nav-item > .nav-link')),
    connect_account = grab('.main_login_sign_up'),
    disconnect_account = grab('.main_logout_sign_up'),
    attachmentSelectEsign = grab('[name=SelectAttachmentEsign]'),
    init_upload_sign = grab('.file_upload_esign_label'),
    file_input_sign = grab('.file_upload_esign'),
    drop_box_sign = grab('.upload_container_esign'),
    sign_doc_holder = grab('.file_holder_sign'),
    esign_recipients = grab('.esign_recipients'),
    self_sign_switch = grab('[name=self_sign_switch]'),
    self_sign_component = grab('.self_sign_component'),
    regular_sign_component = grab('.regular_sign_component'),
    buy_product = grab('.buy_product'),
    hidden_in_compose = grabAll('.hidden-in-compose-mode'),


    attachmentSelectRedact = grab('[name=SelectAttachmentRedact]'),
    file_input_redact = grab('.file_input_redact'),
    file_doc_holder_redact = grab('.file_holder_redact'),
    init_upload_redact = grab('.file_input_label_redact'),
    drop_box_redact = grab('.upload_container_redact'),
    redact_component = grab('.redact_component');

    
    // SETTING GENERAL INPUT DETAILS

    setLoginState().then(function(){

      if (Office.context.mailbox.item.displayReplyForm != undefined){
        for (var i = 0; i < attachments.length; i++){
          if(acceptTableExtensions.includes(attachments[i].name.getExtension())) {
            attachment.push({
              'name': attachments[i].name,
              'value': attachments[i].id
            });
          }
        }
    
        if(attachment.length > 0){
          var default_select = {'default':'Select From Attachment'};
          attachmentSelectEsign.addOptions(attachment,default_select);
          attachmentSelectRedact.addOptions(attachment,default_select);
        }
    
        esign_recipients.empty();
        Array.from( getRecipients() ).forEach(function(recipient){
          esign_recipients.appendChild(
            stringToHTML(String.format(
              recipient_template,
              recipient.emailAddress,
              recipient.displayName,
              base_url))
          );
        });
        loading_cover.classList.add('hide-loading');
      } else {

        Array.from(hidden_in_compose).forEach(function(itm){
          itm.hideItem();
        });
  
        getRecipientsAsync().then(function(rrcp){
          esign_recipients.empty();
          Array.from( rrcp ).forEach(function(recipient){
            esign_recipients.appendChild(
              stringToHTML(String.format(
                recipient_template,
                recipient.emailAddress,
                recipient.displayName,
                base_url))
            );
          });
        }).finally(function(){
          loading_cover.classList.add('hide-loading');
        });

        if (Office.context.requirements.isSetSupported('Mailbox', '1.7')) {
          Office.context.mailbox.item.addHandlerAsync(
              Office.EventType.RecipientsChanged,
              function(r){
                getRecipientsAsync().then(function(rrcp){
                  esign_recipients.empty();
                  Array.from( rrcp ).forEach(function(recipient){
                    esign_recipients.appendChild(
                      stringToHTML(String.format(
                        recipient_template,
                        recipient.emailAddress,
                        recipient.displayName,
                        base_url))
                    );
                  });
                });

              },{},function(asyncResult){

              }
          );

        }

      }
    });


    nav_links.forEach(function(link) {
      link.addEventListener('click', function(evt) {
        evt.preventDefault();
        nav_links.forEach( function(l) {
          l.classList.remove('active');
          l.setAttribute('aria-selected',false);
          document.getElementById(l.hash.substr(1)).classList.remove('show','active');
        });
        this.classList.add('active');
        this.setAttribute('aria-selected',true);
        document.getElementById(this.hash.substr(1)).classList.add('show','active');
      });
    });


    connect_account.addEventListener('click',connectAccount);
    disconnect_account.addEventListener('click',disconnectAccount);

    Array.of(file_input_sign,file_input_redact).forEach(function(e){
      e.accept = acceptableFiles.join(",");
    });


    self_sign_switch.addEventListener('change',function(){
      if(this.checked==true){
        self_sign_component.showItem();
        regular_sign_component.hideItem();
      }else {
        self_sign_component.hideItem();
        regular_sign_component.showItem();
      }
    });


    buy_product.addEventListener('click',function(){
      Office.context.ui.displayDialogAsync(base_url + '/pricing',function(dbr){
        if(dbr.status === Office.AsyncResultStatus.Succeeded) {
          refreshOnDialogClose = true;
          dialogBox = dbr.value;
          dialogBox.addEventHandler(Office.EventType.DialogMessageReceived,processMessage);
          dialogBox.addEventHandler(Office.EventType.DialogEventReceived, processDialogEvent);
        } else {
          $.notify("Something went wrong internally, please contact support at support@docendorse.com",'error');
        }
      });
    });


      
    // ===================
    // ESIGN
    // ===================
    uploadSign = new UploadListener(init_upload_sign,file_input_sign,drop_box_sign);
    uploadSign.on('processFiles',function(files){
      fileStore = [];
      if(sign_doc_holder.children.length == 0){
        sign_doc_holder.appendChild(
          stringToHTML(String.format(file_template,files[0].name,files[0].name,base_url,'upload'))
        );
        fileStore.push.apply(fileStore,files);
      } else {
        $.notify('Please remove the file that is currently selected','error');
      }
    });


    attachmentSelectEsign.addEventListener('change',function(evt){
      var value = this.value, title = this.options[this.selectedIndex].text;
      if(value.length > 0 && sign_doc_holder.children.length == 0){
        sign_doc_holder.appendChild(
          stringToHTML(String.format(file_template,title,value,base_url,'attachment'))
        );
      } else if (sign_doc_holder.children.length > 0){
        $.notify('Please remove the file that is currently selected','error');
      }
    });

    
    sign_doc_holder.addEventListener('click',function(evt){
      if(evt.target.closest('.mdi-close-circle-outline')){
        evt.target.parentNode.parentNode.removeSelf();
        clearFileInputs();
      }
    });


    regular_sign_component.addEventListener('click',function(evt){
      if (evt.target.closest('[name=get_document_signed]')){
        if(isRunning == false){
          if(!isFormEmpty(this)){
            if (sign_doc_holder.children.length > 0){
              isRunning = true;
              evt.target.querySelector('.fa-spin').makeVisible();
              var data = Serialize(this),
              msdoc = uniqid("msdoc_"),
              recipients = getSelectedRecipients(this),
              fileSelected = Serialize(sign_doc_holder.children[0]);
              data.recipients = recipients;
              if (fileSelected.document_type == 'attachment'){
                addAttachmentBasedRequest(
                  msdoc,
                  data,
                  fileSelected.document_id,
                  'esign_document'
                ).then(getDocumentSigned)
                .catch(handleUploadError)
                .finally(function(){
                  isRunning = false;
                  evt.target.querySelector('.fa-spin').makeHidden();
                  resetForm(regular_sign_component);
                  SetSelect(attachmentSelectEsign,'',false);
                  sign_doc_holder.empty();
                });
              }else {
                addUploadBasedRequest(
                  msdoc,
                  data,
                  'esign_document'
                ).then(getDocumentSigned)
                .catch(handleUploadError)
                .finally(function(){
                  isRunning = false;
                  evt.target.querySelector('.fa-spin').makeHidden();
                  resetForm(regular_sign_component);
                  SetSelect(attachmentSelectEsign,'',false);
                  sign_doc_holder.empty();
                });
              }
            } else {
              $.notify('You need to select a document.','error');
            }
          } else {
            this.classList.add('was-validated');
            $.notify('There are some data missing.','error');
          }
        } else {
          $.notify('Document signing is in progress please wait...','error');
        }
      }
    });


    self_sign_component.addEventListener('click',function(evt){
      if (evt.target.closest('[name=get_document_self_signed]')){
        if(isRunning == false){
          if(!isFormEmpty(this)){
            if (sign_doc_holder.children.length > 0){
              isRunning = true;
              evt.target.querySelector('.fa-spin').makeVisible();
              var data = Serialize(this),
              msdoc = uniqid("msdoc_"),
              fileSelected = Serialize(sign_doc_holder.children[0]);
              if (fileSelected.document_type == 'attachment'){
                addAttachmentBasedRequest(
                  msdoc,
                  data,
                  fileSelected.document_id,
                  'esign_document'
                ).then(getDocumentSelfSigned)
                .catch(handleUploadError)
                .finally(function(){
                  isRunning = false;
                  evt.target.querySelector('.fa-spin').makeHidden();
                  resetForm(self_sign_component);
                  SetSelect(attachmentSelectEsign,'',false);
                  sign_doc_holder.empty();
                });
              }else {
                addUploadBasedRequest(
                  msdoc,
                  data,
                  'esign_document'
                ).then(getDocumentSelfSigned)
                .catch(handleUploadError)
                .finally(function(){
                  isRunning = false;
                  evt.target.querySelector('.fa-spin').makeHidden();
                  resetForm(self_sign_component);
                  SetSelect(attachmentSelectEsign,'',false);
                  sign_doc_holder.empty();
                });
              }
            } else {
              $.notify('You need to select a document.','error');
            }
          } else {
            this.classList.add('was-validated');
            $.notify('There are some data missing.','error');
          }
        } else {
          $.notify('Document signing is in progress please wait...','error');
        }
      }
    });


    // ===============================
    //  REDACT
    // =============================== 

    uploadRedact = new UploadListener(init_upload_redact,file_input_redact,drop_box_redact);
    uploadRedact.on('processFiles',function(files){
      fileStore = [];
      if(file_doc_holder_redact.children.length == 0){
        file_doc_holder_redact.appendChild(
          stringToHTML(String.format(file_template,files[0].name,files[0].name,base_url,'upload'))
        );
      fileStore.push.apply(fileStore,files);
      } else {
        $.notify('Please remove the file that is currently selected','error');
      }
    });

    attachmentSelectRedact.addEventListener('change',function(evt){
      var value = this.value, title = this.options[this.selectedIndex].text;
      if(value.length > 0 && file_doc_holder_redact.children.length == 0){
        file_doc_holder_redact.appendChild(
          stringToHTML(String.format(file_template,title,value,base_url,'attachment'))
        );
      } else if (file_doc_holder_redact.children.length > 0){
        $.notify('Please remove the file that is currently selected','error');
      }
    });

    file_doc_holder_redact.addEventListener('click',function(evt){
      if(evt.target.closest('.mdi-close-circle-outline')){
        evt.target.parentNode.parentNode.removeSelf();
        clearFileInputs();
      }
    });



    redact_component.addEventListener('click',function(evt){
      if (evt.target.closest('[name=get_document_redacted]')){
        if(isRunning == false){
          if(!isFormEmpty(this)){
            if (file_doc_holder_redact.children.length > 0){
              isRunning = true;
              evt.target.querySelector('.fa-spin').makeVisible();
              var data = Serialize(this),
              msdoc = uniqid("msdoc_"),
              fileSelected = Serialize(file_doc_holder_redact.children[0]);
              if (fileSelected.document_type == 'attachment'){
                addAttachmentBasedRequest(
                  msdoc,
                  data,
                  fileSelected.document_id,
                  'redact_document'
                ).then(getDocumentRedacted)
                .catch(handleUploadError)
                .finally(function(){
                  isRunning = false;
                  evt.target.querySelector('.fa-spin').makeHidden();
                  resetForm(redact_component);
                  SetSelect(attachmentSelectRedact,'',false);
                  file_doc_holder_redact.empty();
                });
              }else {
                addUploadBasedRequest(
                  msdoc,
                  data,
                  'redact_document'
                ).then(getDocumentRedacted)
                .catch(handleUploadError)
                .finally(function(){
                  isRunning = false;
                  evt.target.querySelector('.fa-spin').makeHidden();
                  resetForm(redact_component);
                  SetSelect(attachmentSelectRedact,'',false);
                  file_doc_holder_redact.empty();
                });
              }
            } else {
              $.notify('You need to select a document.','error');
            }
          } else {
            this.classList.add('was-validated');
            $.notify('There are some data missing.','error');
          }
        } else {
          $.notify('Document signing is in progress please wait...','error');
        }
      }
    });

  } else {
    secondary_container.showItem();
    main_container.hideItem();
    loading_cover.classList.add('hide-loading');
  }

}



Office.onReady(function(info){
  if (navigator.userAgent.indexOf("Trident") == -1){
    installAddIn(info);
  }
});



function getRecipients(){
  var emailItems = [], 
  item = Office.context.mailbox.item;
  if(item.itemType ==  Office.MailboxEnums.ItemType.Appointment){
    if(item.requiredAttendees != ""){
      emailItems.push.apply(emailItems,item.requiredAttendees);
    }
    if(item.optionalAttendees != ""){
      emailItems.push.apply(emailItems,item.optionalAttendees);
    }
  } else {
    emailItems.push(item.sender);
    if (item.cc != ''){
      emailItems.push.apply(emailItems,item.cc);
    }
    if (item.bcc != ''){
      emailItems.push.apply(emailItems,item.bcc);
    }
  }
  return emailItems;
}


function createPromiseCapability(item){
  return new Promise(function(resolve,reject){
    item.getAsync(function(asyncResult){
      if (asyncResult.status === Office.AsyncResultStatus.Failed) {
          reject(asyncResult.error.message);
      } else {
        resolve(asyncResult.value);
      }
      });
  });
}

function getRecipientPromises(){
  var item = Office.context.mailbox.item;
  if (item.itemType === Office.MailboxEnums.ItemType.Appointment) {
      return Promise.all([
        createPromiseCapability(item.requiredAttendees),
        createPromiseCapability(item.optionalAttendees)
      ]);
  } else {
    return Promise.all([
      createPromiseCapability(item.to),
      createPromiseCapability(item.cc),
      createPromiseCapability(item.bcc),
    ]);
  }
}


function getRecipientsAsync(){
  var emailItems = [];
  return new Promise(function(resolve,reject){
    getRecipientPromises().then(function(promises){
      for(var i =0; i<promises.length; i++){
        var _promise = promises[i];
        if (_promise.length > 0){
          emailItems.push.apply(emailItems,_promise);
        }
      }
      resolve(emailItems);
    })
    .catch(reject);
  });
}



function setLoginState(){
  return new Promise(function(resolve,reject){
    loginState({'action': 'get_app_state'}).then(function(_state){ 
      var state = JSON.parse(_state),
      scene = null;
      if (state.status == 'user_authenticated_reg_no_plan') {
        scene = setScene('signed_in_no_product',state);
      } else if (state.status == 'user_authenticated_has_product'){
        scene = setScene('signed_in',state);
      } else if (state.status == 'account_unverified') {
        scene = setScene('signed_out');
        $.notify('Your email/account has not been verified. Please check your email for the verification link.','error');
      } else {
        scene = setScene('signed_out');
      }
      scene.then(resolve).catch(reject);
    }).catch(reject);
  });
}


function processDialogEvent(arg){
  switch (arg.error) {
      case 12002:
        $.notify("Cannot load page please contact support at support@docendorse.com.",'error');
        break;
      case 12003:
        $.notify("An unsecured page is being opened please contact support at support@docendorse.com","error");
        break;
      case 12006: 
        if(refreshOnDialogClose){
          setLoginState();
          refreshOnDialogClose = false;
        }
        break;
      case 12009:
        $.notify("Blocking the pop up will prevent the operation from being carried out. Please enable the popup to continue.","error"); 
        break;
      case 12011:
        $.notify("Your browser is configured to block popups, please enable popup or use different browser to continue.","error"); 
        break;
      default: break;
  }
}


function processMessage(arg) {
  var message = JSON.parse(arg.message);
    switch(message.status){
      case 'success_app_registered':
      case 'success_app_updated':
        setToken(message);
        setLoginState().finally(function(){
          dialogBox.close();
          $.notify(message.description,'success');
        });
        break;
      case 'success_app_revoked':
      case 'not_connected_app_connection':
        localStorage.clear();
        setLoginState().finally(function(){
          dialogBox.close();
          $.notify(message.description,'success');
        });
        break;
      case 'close_dialog':
        dialogBox.close();
        break;
      case 'signature_request_sent':
        resetForm(grab('#pills-profile'));
        dialogBox.close();
        $.notify(message.description,'success');
        break;
      case 'redact_document_completed':
        dialogBox.close();
        postCompletion(message,'redacted document');
        break;
      case 'self_signed_document_completed':
        dialogBox.close();
        postCompletion(message,'signed document');
        break;
      case 'purchase_addon_complete':
        setLoginState().finally(function(){
          dialogBox.close();
          $.notify(message.description,'success');
        });
        break;
    }
}

function setScene(scene,data = {}){
  var visible_on_logged_out = Array.from(grabAll('.visible_on_logged_out')),
  visible_on_logged_in = Array.from(grabAll('.visible_on_logged_in')),
  paid_esign_plan = Array.from(grabAll('.paid_esign_plan')),
  esign_tip = grab('.esign_tip'),
  redact_tip = grab('.redact_tip'),
  paid_redact_addon = Array.from(grabAll('.paid_redact_plan')),
  profile = grab('.box-profile'),
  buy = grab('.buy_product'),
  _data = {};

  esign_tip.innerHTML = "You need to connect your app to your DocEndorse account to esign documents";
  
  switch(scene){
    case 'signed_in':
      visible_on_logged_in.forEach(function(el) { el.showItem(); } );
      visible_on_logged_out.forEach(function(el) { el.hideItem(); } );
      paid_esign_plan.forEach(function(el) { el.showItem(); } );
      _data = data;
      buy.hideItem();
      esign_tip.hideItem();

      if(!data.IsARedactUser){
        paid_redact_addon.forEach(function(el){el.hideItem()});
        redact_tip.showItem();
      } else {
        paid_redact_addon.forEach(function(el){el.showItem()});
        redact_tip.hideItem();
      }
      break;
    case 'signed_out':
      visible_on_logged_in.forEach(function(el){ el.hideItem(); } );
      visible_on_logged_out.forEach(function(el){ el.showItem(); } );
      paid_esign_plan.forEach(function(el) { el.hideItem(); } );
      paid_redact_addon.forEach(function(el){el.hideItem();});
      redact_tip.showItem();
      esign_tip.showItem();
      break;
    case 'signed_in_no_product':
      visible_on_logged_in.forEach(function(el) { el.showItem(); } );
      visible_on_logged_out.forEach(function(el) { el.hideItem(); } );
      paid_esign_plan.forEach(function(el) { el.hideItem(); } );
      paid_redact_addon.forEach(function(el){el.hideItem()});
      redact_tip.showItem();
      esign_tip.showItem();

      esign_tip.innerHTML = "You need to purchase a DocEndorse subscription.";
      _data = data;
      buy.showItem();

      break;
  }
  return setHTMLData(profile,_data);
}



function loginState( data ){
  return webFetchPromise({
    'url': base_url + '/api/public/connected_apps_manager.php',
    'type': 'post',
    'content': "application/json",
    'payload': data,
    'responseType': 'text',
    'authorization': get_token()
  });
}


function setToken(data){
  localStorage.setItem("access_token", data.access_token);
}


function get_token(){
  return localStorage.getItem("access_token");
}


function getDocumentSigned(item){
  Office.context.ui.displayDialogAsync(base_url + '/routines_ms_app?instruct=sign_document_connected_app&app_code=microsoft_doc_man_outlook&app_transaction_id=' + item.id,function(dbr){
    if(dbr.status === Office.AsyncResultStatus.Succeeded) {
      dialogBox = dbr.value;
      dialogBox.addEventHandler(Office.EventType.DialogMessageReceived,processMessage);
      dialogBox.addEventHandler(Office.EventType.DialogEventReceived, processDialogEvent);
    } else {
      $.notify("Something went wrong internally, please contact support at support@docendorse.com",'error');
    }
  });
}



function getDocumentSelfSigned(item){
  Office.context.ui.displayDialogAsync(base_url + '/routines_ms_app?instruct=self_sign_document_connected_app&app_code=microsoft_doc_man_outlook&app_transaction_id=' + item.id,function(dbr){
    if(dbr.status === Office.AsyncResultStatus.Succeeded) {
      dialogBox = dbr.value;
      dialogBox.addEventHandler(Office.EventType.DialogMessageReceived,processMessage);
      dialogBox.addEventHandler(Office.EventType.DialogEventReceived, processDialogEvent);
    } else {
      $.notify("Something went wrong internally, please contact support at support@docendorse.com",'error');
    }
  });
}


function getDocumentRedacted(item){
  Office.context.ui.displayDialogAsync(base_url + '/routines_ms_app?instruct=redact_document_connected_app&app_code=microsoft_doc_man_outlook&app_transaction_id=' + item.id,function(dbr){
    if(dbr.status === Office.AsyncResultStatus.Succeeded) {
      dialogBox = dbr.value;
      dialogBox.addEventHandler(Office.EventType.DialogMessageReceived,processMessage);
      dialogBox.addEventHandler(Office.EventType.DialogEventReceived, processDialogEvent);
    } else {
      $.notify("Something went wrong internally, please contact support at support@docendorse.com",'error');
    }
  });
}


function connectAccount(){
  Office.context.ui.displayDialogAsync( base_url + '/routines_ms_app?instruct=connect_app&app_code=microsoft_doc_man_outlook',function(dbr){
    if(dbr.status === Office.AsyncResultStatus.Succeeded) {
      dialogBox = dbr.value;
      dialogBox.addEventHandler(Office.EventType.DialogMessageReceived,processMessage);
      dialogBox.addEventHandler(Office.EventType.DialogEventReceived, processDialogEvent);
    } else {
      $.notify("Something went wrong internally, please contact support at support@docendorse.com",'error');
    }
  });
}


function disconnectAccount() {
  Office.context.ui.displayDialogAsync(base_url + '/routines_ms_app?instruct=disconnect_app&app_code=microsoft_doc_man_outlook',function(dbr){
    if(dbr.status === Office.AsyncResultStatus.Succeeded) {
      dialogBox = dbr.value;
      dialogBox.addEventHandler(Office.EventType.DialogMessageReceived,processMessage);
      dialogBox.addEventHandler(Office.EventType.DialogEventReceived, processDialogEvent);
    } else {
      $.notify("Something went wrong internally, please contact support at support@docendorse.com",'error');
    }
  });
}


function purchaseSubscription(){
  Office.context.ui.displayDialogAsync(base_url + '/routines_ms_app?instruct=purchase_subscription&app_code=microsoft_doc_man_outlook',function(dbr){
    if(dbr.status === Office.AsyncResultStatus.Succeeded) {
      refreshOnDialogClose = true;
      dialogBox = dbr.value;
      dialogBox.addEventHandler(Office.EventType.DialogMessageReceived,processMessage);
      dialogBox.addEventHandler(Office.EventType.DialogEventReceived, processDialogEvent);
    } else {
      $.notify("Something went wrong internally, please contact support at support@docendorse.com",'error');
    }
  });
}


function getSelectedRecipients(element){
  return Array.from(
      element.querySelectorAll("input:checked")
      ).map(function(ch){
      return {
        RecipientEmail: ch.getAttribute("name"), 
        RecipientName: getElementSiblings(ch)[1].textContent
      };
  });
}


function addAttachmentBasedRequest(transactionId,data,attachmentId,type){
  return new Promise(function(resolve,reject){
    Office.context.mailbox.getCallbackTokenAsync(function(fD){
      if (fD.status === Office.AsyncResultStatus.Succeeded) {
        loginState({
          action: 'process_outlook_attachment',
          token: fD.value,
          ewsUrl: Office.context.mailbox.ewsUrl,
          params: data,
          attachment_id: attachmentId,
          transactiontype: type,
          docutype: 'user_document_to_sign',
          transactionid: transactionId,
        }).then(function(res){
          var state = JSON.parse(res);
          if (state.status == 'success'){
            resolve(state);
          } else {
            reject(state);
          }
        }).catch(reject);
      } else {
        reject({
          'status': 'system_error',
          'message': fD.error.message
        });
      }
    });
  });
}


function addUploadBasedRequest(transactionId,data,type){
  return new Promise(function(resolve,reject){
      var xhr = new XMLHttpRequest(),
      fD = new FormData();
      fD.append('file-1',fileStore[0]);
      fD.append('info',
        new Blob([JSON.stringify({
          params: data,
          transactiontype: type,
          docutype: 'user_document_to_sign',
          transactionid: transactionId,
        })], { type: "application/json"})
      );

      xhr.addEventListener('load', function (e) {
          if (xhr.status == 200) {
            var data = JSON.parse( xhr.responseText );
            if(data.status == 'success'){
              resolve(data);
            }else {
              reject(data);
            }
          } else {
              reject('https status: ' + xhr.status + ' message: ' + xhr.response);
          } 
      });

      xhr.addEventListener('error',function(e){
        reject(xhr.response || 'Network request failed');
      });

      xhr.addEventListener('timeout', function(e){
          reject( 'Upload timed out');
      });
      xhr.open("POST",'https://docendorse.com/api/public/connected_apps_upload_form_data.php');
      xhr.setRequestHeader('Authorization','Bearer ' + get_token());
      xhr.send(fD);
      clearFileInputs();
  });
}



function handleUploadError(err){
  if(err.status == 'no_perms'){
    $.notify('Your app is disconnected. Please reconnect','error');
    setLoginState();
  } else if (err.status == 'purchase_plan_app_connection'){
    purchaseSubscription();
  } else if (err.status == 'purchase_redact_addon'){
    $.notify("You need to be assigned to a redact add-on, please contact your IT department.",'error');
  } else {
    $.notify("Could not upload file please try again or contact support at support@docendorse.com",'error');
  }
}


function clearFileInputs(){
  Array.of(uploadRedact,uploadSign).forEach(function(up){
    if (isVarSet(up)) up.clearFileInput();
  });
}



function postCompletion(message,operation){

  if (Office.context.mailbox.item.displayReplyForm != undefined){
    if(message.link.length>0){
      getConfirmation(
        'modal-confirm',String.format('{0} Do you want to reply to this email with the {1} as an attachment?',message.description,operation),
        'Reply','Continue').then(function(r){ if(r){

          Office.context.mailbox.item.displayReplyAllForm(
            {
              'htmlBody' : 'Please see the attached document.',
              'attachments' :
              [
                {
                  'type' : Office.MailboxEnums.AttachmentType.File,
                  'name' : message.name,
                  'url' : base_url.concat(message.link)
                }
              ],
              'callback' : function(asyncResult) {
                if (asyncResult.status === Office.AsyncResultStatus.Failed){
                  $.notify('Could not add attachment to reply.')
                } else {
                    var attachmentID = asyncResult.value;
                }
              }
            });

        }});
    } else {
      $.notify(message.description,'success');
    }
  } else {
    if(message.link.length>0){
      getConfirmation(
        'modal-confirm', String.format('{0} Do you want to add the {1} as an attachment to your email?',message.description,operation),
        'Yes','No').then(function(r){ if(r){

          Office.context.mailbox.item.addFileAttachmentAsync(
            base_url.concat(message.link),
            message.name,
            { asyncContext: null },
            function (asyncResult) {
                if (asyncResult.status === Office.AsyncResultStatus.Failed){
                    $.notify('Could not add attachment to new email.')
                } else {
                    var attachmentID = asyncResult.value;
                }
            });
        }});
    } else {
      $.notify(message.description,'success');
    }
  }
}



$.notify.addStyle('modal-confirm',{
  html: '<div class="notifyjs-modal-confirm">'+
          '<div class="confirm-message-content">'+
            '<div class="confirm-message-header flex-column">'+
              '<div class="icon-box">'+
                '<i class="mdi mdi-exclamation"></i>'+
              '</div>'+
              '<h4 class="confirm-message-title w-100" data-notify-html="title"></h4>'+
            '</div>'+
            '<div class="confirm-message-body">'+
            '</div>'+
            '<div class="confirm-message-footer justify-content-center">'+
              '<button type="button" class="btn btn-secondary no" data-notify-text="no_button">Cancel</button>'+
              '<button type="button" class="btn btn-danger yes" data-notify-text="button">Confirm</button>'+
            '</div>'+
          '</div>'+
        '</div>'
});




var getConfirmation = function(type,title,btn = 'Confirm',noBtn = 'Cancel') {
  return new Promise(function(resolve){
      $(document).on('click', '.notifyjs-'.concat(type,'-base .no'), function() {
        $(this).trigger('notify-hide');
        resolve(false);
      });
      $(document).on('click', '.notifyjs-'.concat(type,'-base .yes'), function() {
        $(this).trigger('notify-hide');
        resolve(true);
      });

      $.notify({
        title: title,
        button: btn,
        no_button: noBtn
      }, { 
        style: type,
        autoHide: false,
        clickToHide: false
      });
  });
};
